import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const NavStyles = styled.nav`
  grid-area: nav;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  > ul {
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
    align-items: center;
    text-align: center;
    list-style: none;
  }
`;

const NavItem = styled.li`
  > a {
    font-size: 1.5rem;
    text-decoration: none;
    &:hover {
      color: var(--blue);
    }
    &[aria-current="page"] {
      color: var(--blue);
    }
  }
`;

const CompanyName = styled.li`
  > a {
    font-size: 2.5rem;
    text-decoration: none;
    &:hover {
      color: var(--blue);
    }
    &[aria-current="page"] {
      color: var(--blue);
    }
  }
`;

export default function Nav() {
  return (
    <NavStyles>
      <ul>
        <NavItem>
          <Link to="/about">About</Link>
        </NavItem>
        <CompanyName>
          <Link to="/">Namaste Northstar</Link>
        </CompanyName>
        <NavItem>
          <Link to="/contact">Contact</Link>
        </NavItem>
      </ul>
    </NavStyles>
  );
}

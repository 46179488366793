import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --blue: #322AAD;
    --black: #2E2E2E;
    --yellow: #ffc600;
    --white: #fff;
    --lightGrey: #efefef;
    --grey: grey;
    --greybg: #E5E5E5;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--blue);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  .gatsby-image-wrapper img[src*=base64] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }
  .scroll {
    background-attachment: scroll;
  }
  .fixed {
    background-attachment: fixed;
  }
`;

export default GlobalStyles;

import React from "react";
import styled from "styled-components";

const FooterContainer = styled.footer`
  grid-area: footer;
  display: grid;
  grid-template-areas: "copyright";
  min-height: 150px;
  justify-content: center;
  align-items: end;
`;

const Copyright = styled.p`
  grid-area: copyright;
`;
export default function Footer() {
  return (
    <FooterContainer>
      <Copyright>&copy; Namaste Northstar {new Date().getFullYear()}</Copyright>
    </FooterContainer>
  );
}

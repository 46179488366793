import React from "react";
import styled from "styled-components";
import "normalize.css";
import Nav from "./Nav";
import Footer from "./Footer";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";

const ContentStyles = styled.div`
  grid-area: content;
  background: white;
`;

const Container = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "nav"
    "content"
    "footer";
`;
export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <Container>
        <Nav />
        <ContentStyles>{children}</ContentStyles>
        <Footer />
      </Container>
    </>
  );
}
